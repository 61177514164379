<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap style="margin: -1px !important">
      <v-flex xs12>
        <v-img
          class="align-center"
          :contain="$vuetify.breakpoint.name == 'xs' ? false : true"
          :cover="$vuetify.breakpoint.name != 'xs' ? false : true"
          :height="$vuetify.breakpoint.name == 'xs' ? '260px' : 'auto'"
          :src="require('./../../assets/Images/bannerJob.jpg')"
        >
         <v-layout wrap justify-center>
          <v-flex xs12 align-self-center text-center pt-lg-14>
            <span :style="$vuetify.breakpoint.name=='xs' || $vuetify.breakpoint.name=='sm'?'font-size:55px':'font-size:75px'" class="oswaldbold cwhite">
              JOBS
            </span>
          </v-flex>
        </v-layout>
        </v-img>
      </v-flex>
    </v-layout>
    <v-container>
      <v-layout wrap justify-center py-16>
        <v-flex xs12 text-left v-if="job">
          <v-layout wrap style="font-size: 15px !important;" class="cblack popregular">
            <v-flex xs12 py-2>
              <span>
                <b>Job Category: </b>
                <span>{{ job.jobCategory.name }}</span>
              </span>
            </v-flex>
            <v-flex xs12 py-2>
              <span>
                <b>Location: </b>
                <span>{{ job.location }}</span>
              </span>
            </v-flex>
            <v-flex xs12 py-2>
              <span>
                <b>Date Posted: </b>
                <span>{{ DateFormatted(job.createddate) }}</span>
              </span>
            </v-flex>
            <!-- <v-flex xs12 py-5>
              <v-btn large dark depressed class="apply" @click="scrollToElement">
                <span style="font-size: 13px; padding: 5px">
                  <b> APPLY NOW</b></span
                >
              </v-btn>
            </v-flex> -->
            <v-flex xs12 py-8>
              <div style="border-bottom: 1px solid #828282"></div>
            </v-flex>
            <v-flex xs12 class="cblack">
              <span v-html="job.content"></span>
            </v-flex>

            <v-flex xs12 py-8>
              <div style="border-bottom: 1px solid #828282"></div>
            </v-flex>
            <v-flex xs12 py-2 pr-sm-4 ref="scrollToMe">
              <Form :jobTitle="job._id" :jobCategory="job.jobCategory._id" />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-else xs12 align-self-center text-center py-16>
          <span class="popregular small cblack">
            Currently we have no openings available!
          </span>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import Form from "./form";
import moment from "moment";
export default {
  components: {
    Form,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      job: null,
    };
  },
  beforeMount() {
    if (this.$route.query.id) this.getData();
  },
  methods: {
      DateFormatted(params) {
      // moment().format("dddd, MMMM Do YYYY, h:mm:ss a")
      // return params ? moment(params).format("DD-MMMM-Y <br> hh:mm A") : "";

      
      params= moment(params).subtract(5, 'h').subtract(30, 'm')
      return params ? moment(params).format("DD-MMMM-Y") : "";
    },
    scrollToElement() {
      const el = this.$refs.scrollToMe;

      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/job/title/get",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.job = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.apply {
  background-color: #005f32 !important;
}
.apply:hover {
  background-color: #ecb338 !important;
}
/* .onhov {
  background-color: #ffffff !important;
}
.onhov:hover {
  background-color: #005f32 !important;
  color: #ffffff !important;
} */
</style>
